import React, { useRef, useEffect } from "react"

import { Paragraph } from "../uikit/Typographic"
import Button from "../../newUikit/Button"
import * as S from "./styled"

const MainSection = ({ content, openModal }) => {
  const ref = useRef()

  useEffect(() => {
    if (!ref.current) return
  }, [ref])

  return (
    <S.Section id="MainSection">
      <S.Title>{content.title}</S.Title>
      <Paragraph>{content.description}</Paragraph>
      <S.ButtonsContainer>
        {content.tryInBrowserLabel && (
          <Button
            color="cyan"
            target="_blank"
            url={content.tryInBrowser}
          >
            {content.tryInBrowserLabel}
          </Button>
        )}
      </S.ButtonsContainer>
      <S.Container>
      <S.AdditionalText>
            <span className="text">{content.forSales}</span>
            <Button size="sm" inverse onClick={openModal}>
              {content.salesBtn}
            </Button>
      </S.AdditionalText>
      </S.Container>

      { content.video && <S.Video ref={ref}>
          <iframe src="https://demo.arcade.software/lWMndRj4Bau3tCqzXdoC?embed&show_copy_link=true" title="Dasha Studio" frameBorder="0" loading="lazy" webKitAllowFullScreen={true} mozAllowFullScreen={true} allowFullScreen={true} allow="clipboard-write" 
          style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", colorScheme: "light"}}></iframe>
      </S.Video> }

      {/*<S.Container>
        <S.CodeBlock samples={content.code} />
        <S.Terminal>
          {!isTerminal ? (
            <>
              <TerminalIcon />
              <Button onClick={() => setTerminal(true)}>
                Run <RunIcon />
              </Button>
            </>
          ) : (
            <Terminal />
          )}
        </S.Terminal>
          </S.Container>*/}
    </S.Section>
  )
}

export default MainSection
